import _ from 'lodash';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const KEY_HWVARIANT_PREFIX = 'hwvariant_';
export const KEY_REGION_PREFIX = 'region_';
export const KEY_VEHICLE_STATUS_PREFIX = 'vehicle_';
export const KEY_BRAND_PREFIX = 'brand_';
export const KEY_VVARIANT_PREFIX = 'vvariant_';
export const VEHICLE_ACTIVATED_ID = 'service.heatmap.filter.settings.vehicle.activated';
export const VEHICLE_NOT_ACTIVATED_ID = 'service.heatmap.filter.settings.vehicle.not.activated';

const KEY_VEHICLE_ACTIVATED = `${KEY_VEHICLE_STATUS_PREFIX}activated`;
const KEY_VEHICLE_NOT_ACTIVATED = `${KEY_VEHICLE_STATUS_PREFIX}notactivated`;

/**
 * Maps positions from devices for ui.
 * @param  {[Object]} devices the devices with the positions to be mapped
 * @return {[Object]}         the mapped positions
 */
export function mapPositions(devices) {
    return _.compact(_.map(devices, function(device) {
        if (device.lat && device.lon) {
            return {
                lat: device.lat,
                lng: device.lon,
                isCM4: device.hwv === 'CM4',
                vin: device.vin,
                mileage: device.mileage,
            };
        }
    }));
}

function addEntry(map, key, text) {
    if (!map.has(key)) {
        map.set(key, {dataField: key, checked: true, text: text});
    }
}

function addCheckedEntry(field, items, prefix, getId) {
    if (isBlank(field)) {
        addEntry(items, prefix.concat('unknown'), 'UNKNOWN');
    } else {
        addEntry(items, getId(field), field);
    }
}

/**
 * Maps filter settings for ui.
 * @param  {[Object]} devices the devices to be mapped
 * @return {[Object]}         the ordered and unique mapped settings
 */
export function mapFilterSettings(devices) {
    const items = new Map();

    devices.forEach(device => {
        if (device.lat && device.lon) {
            addEntry(items, KEY_VEHICLE_ACTIVATED,
                <FormattedMessage id={VEHICLE_ACTIVATED_ID}/>);
            addEntry(items, KEY_VEHICLE_NOT_ACTIVATED,
                <FormattedMessage id={VEHICLE_NOT_ACTIVATED_ID}/>);

            const regionText = getRegionText(device.gw); // EU, LATAM, UNKNOWN
            addEntry(items, KEY_REGION_PREFIX.concat(regionText.toLowerCase()), regionText);

            addCheckedEntry(device.hwv, items, KEY_HWVARIANT_PREFIX, getHardwareVariantId);
            addCheckedEntry(device.brand, items, KEY_BRAND_PREFIX, getBrandId);
            addCheckedEntry(device.vv, items, KEY_VVARIANT_PREFIX, getVehicleVariantId);
        }
    });

    return _.orderBy(Array.from(items.values()), ['dataField'], ['asc']);
}

function getHardwareVariantId(hwv) {
    return KEY_HWVARIANT_PREFIX.concat(hwv.trim().split(' ').join('-').toLowerCase());
}

function getBrandId(brand) {
    return KEY_BRAND_PREFIX.concat(brand.trim().replace(/[^a-zA-Z0-9 ]/g, '').split(' ').join('-').toLowerCase());
}

function getVehicleVariantId(vv) {
    return KEY_VVARIANT_PREFIX.concat(vv.trim().toLowerCase());
}

export function isBlank(str) {
    return !str || str.length === 0 || !str.trim();
}

export function getRegionText(gw) {
    switch (gw) {
        case 'tbm3':
            return 'EU';
        case 'tbm3.latam':
            return 'LATAM';
        default:
            // gateway equals null case
            return 'UNKNOWN';
    }
}

export function getActVehicleKey(act) {
    return act ? KEY_VEHICLE_ACTIVATED : KEY_VEHICLE_NOT_ACTIVATED;
}
