import L from 'leaflet';

function fillMarkerList(positions) {
    const markerList = [];
    positions.forEach(pos => {
        if (pos.isCM4) {
            const vin = (Math.random() + 1).toString(36).substring(7);
            const mileage = Math.floor(10000 + Math.random() * 90000);
            const marker = L.marker([pos.lat, pos.lng])
                .bindPopup(`
                        <div>
                            <p>vin: ${vin}</p>
                            <p>milaeage: ${mileage}</p>
                        </div>`);
            markerList.push(marker);
        }
    });
    return markerList;
}

export function cm4MarkerLayer(map, positions, isSelected) {
    const cm4MarkerLayerGroup = L.layerGroup();

    if (isSelected) {
        cm4MarkerLayerGroup.addTo(map);
    }

    const markerList = fillMarkerList(positions);
    markerList.forEach(m => cm4MarkerLayerGroup.addLayer(m));

    return cm4MarkerLayerGroup;
}
